var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Basic Datepicker" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("code", [_vm._v("<b-form-datepicker>")]),
        _c("span", [
          _vm._v(
            " is a BootstrapVue custom date picker input form control, which provides full WAI-ARIA compliance and internationalization support. "
          )
        ])
      ]),
      _c("label", { attrs: { for: "example-datepicker" } }, [
        _vm._v("Choose a date")
      ]),
      _c("b-form-datepicker", {
        staticClass: "mb-1",
        attrs: { id: "example-datepicker" },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      }),
      _c("span", [_vm._v("Value: '" + _vm._s(_vm.value) + "'")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }