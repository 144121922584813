var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Control sizing" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Fancy a smaller or larger ")]),
        _c("code", [_vm._v("<b-form-datepicker>")]),
        _c("span", [_vm._v("control? Set the ")]),
        _c("code", [_vm._v("size")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("'sm'")]),
        _c("span", [_vm._v(" for a smaller form control, or ")]),
        _c("code", [_vm._v("'lg'")]),
        _c("span", [
          _vm._v(
            " for a larger form form control. Note this does not affect the size of the popup calendar dialog."
          )
        ])
      ]),
      _c(
        "div",
        [
          _c("label", { attrs: { for: "datepicker-sm" } }, [
            _vm._v("Small date picker")
          ]),
          _c("b-form-datepicker", {
            staticClass: "mb-2",
            attrs: {
              id: "datepicker-sm",
              size: "sm",
              local: "en",
              "today-variant": "danger"
            }
          }),
          _c("label", { attrs: { for: "datepicker-default" } }, [
            _vm._v("Default date picker")
          ]),
          _c("b-form-datepicker", {
            staticClass: "mb-2",
            attrs: {
              id: "datepicker-default",
              local: "en",
              "today-variant": "danger"
            }
          }),
          _c("label", { attrs: { for: "datepicker-lg" } }, [
            _vm._v("Large date picker")
          ]),
          _c("b-form-datepicker", {
            attrs: { id: "datepicker-lg", size: "lg", local: "en" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }