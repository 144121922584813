var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Validation states" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeValidation) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("code", [_vm._v("<b-form-datepicker>")]),
        _c("span", [
          _vm._v(" supports invalid and valid styling via the boolean ")
        ]),
        _c("code", [_vm._v("state")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "div",
        [
          _c("label", { attrs: { for: "datepicker-invalid" } }, [
            _vm._v("Choose a date (invalid style)")
          ]),
          _c("b-form-datepicker", {
            staticClass: "mb-2",
            attrs: { id: "datepicker-invalid", state: false }
          }),
          _c("label", { attrs: { for: "datepicker-valid" } }, [
            _vm._v("Choose a date (valid style)")
          ]),
          _c("b-form-datepicker", {
            attrs: { id: "datepicker-valid", state: true }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }