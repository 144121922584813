var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Variants" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeVariant) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v("The selected date button (background color) defaults to the ")
        ]),
        _c("code", [_vm._v("'primary'")]),
        _c("span", [
          _vm._v(
            " theme variant. You can change this to any of the Bootstrap v4 theme variant colors: "
          )
        ]),
        _c("code", [
          _vm._v("'secondary', 'success', 'danger', 'warning', 'info',")
        ]),
        _c("span", [_vm._v(" etc, via the ")]),
        _c("code", [_vm._v("selected-variant")]),
        _c("span", [_vm._v(" prop.")])
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("b-form-datepicker", {
                staticClass: "mb-1",
                attrs: {
                  local: "en",
                  placeholder: "Primary Variant",
                  "selected-variant": "primary"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("b-form-datepicker", {
                staticClass: "mb-1",
                attrs: {
                  local: "en",
                  placeholder: "Secondary Variant",
                  "selected-variant": "secondary"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("b-form-datepicker", {
                staticClass: "mb-1",
                attrs: {
                  local: "en",
                  placeholder: "Success Variant",
                  "selected-variant": "success"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("b-form-datepicker", {
                staticClass: "mb-1",
                attrs: {
                  local: "en",
                  placeholder: "Warning Variant",
                  "selected-variant": "warning"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("b-form-datepicker", {
                staticClass: "mb-1",
                attrs: {
                  local: "en",
                  placeholder: "Danger Variant",
                  "selected-variant": "danger"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "4" } },
            [
              _c("b-form-datepicker", {
                staticClass: "mb-1",
                attrs: {
                  local: "en",
                  placeholder: "Info Variant",
                  "selected-variant": "info"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }