var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Minimum and maximum dates" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeMinMax) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Restrict the calendar range via the ")]),
        _c("code", [_vm._v("min")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("max")]),
        _c("span", [
          _vm._v(" props. The props accept a date string in the format of ")
        ]),
        _c("code", [_vm._v("YYYY-MM-DD")]),
        _c("span", [_vm._v(" or a ")]),
        _c("code", [_vm._v("Date")]),
        _c("span", [_vm._v(" object.")])
      ]),
      _c("b-form-datepicker", {
        attrs: { min: _vm.min, max: _vm.max, locale: "en" },
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }