var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-datepicker-basic")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-datepicker-placeholder")],
        1
      ),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-datepicker-min-max")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-datepicker-disable-date")],
        1
      ),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-datepicker-validation")],
        1
      ),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-datepicker-state")], 1),
      _c("b-col", { attrs: { lg: "12" } }, [_c("form-datepicker-variant")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-datepicker-button-only")],
        1
      ),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-datepicker-string")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-datepicker-full-width")],
        1
      ),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-datepicker-optional")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-datepicker-size")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-datepicker-internationalization")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }