var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Internationalization" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeInternationalization) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("By default ")]),
        _c("code", [_vm._v("<b-form-datepicker>")]),
        _c("span", [
          _vm._v(
            " will use the browser's default locale, but you can specify the locale (or locales) to use via the "
          )
        ]),
        _c("code", [_vm._v("locale")]),
        _c("span", [_vm._v("prop.")])
      ]),
      _c(
        "div",
        [
          _c("label", { attrs: { for: "example-locales" } }, [
            _vm._v("Locale:")
          ]),
          _c("b-form-select", {
            staticClass: "mb-1",
            attrs: { id: "example-locales", options: _vm.locales },
            model: {
              value: _vm.locale,
              callback: function($$v) {
                _vm.locale = $$v
              },
              expression: "locale"
            }
          }),
          _c("label", { attrs: { for: "example-weekdays" } }, [
            _vm._v("Start weekday:")
          ]),
          _c("b-form-select", {
            staticClass: "mb-1",
            attrs: { id: "example-weekdays", options: _vm.weekdays },
            model: {
              value: _vm.weekday,
              callback: function($$v) {
                _vm.weekday = $$v
              },
              expression: "weekday"
            }
          }),
          _c(
            "div",
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { switch: "", inline: "" },
                  model: {
                    value: _vm.showDecadeNav,
                    callback: function($$v) {
                      _vm.showDecadeNav = $$v
                    },
                    expression: "showDecadeNav"
                  }
                },
                [_vm._v(" Show decade navigation buttons ")]
              ),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "my-1",
                  attrs: { switch: "", inline: "" },
                  model: {
                    value: _vm.hideHeader,
                    callback: function($$v) {
                      _vm.hideHeader = $$v
                    },
                    expression: "hideHeader"
                  }
                },
                [_vm._v(" Hide calendar header ")]
              )
            ],
            1
          ),
          _c("label", { attrs: { for: "example-i18n-picker" } }, [
            _vm._v("Date picker:")
          ]),
          _c(
            "b-form-datepicker",
            _vm._b(
              {
                staticClass: "mb-1",
                attrs: {
                  id: "example-i18n-picker",
                  locale: _vm.locale,
                  "start-weekday": _vm.weekday,
                  "show-decade-nav": _vm.showDecadeNav,
                  "hide-header": _vm.hideHeader
                },
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              },
              "b-form-datepicker",
              _vm.labels[_vm.locale] || {},
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }